import {
  Form, Input, Transfer, Checkbox,
} from 'antd';
import React, { PureComponent } from 'react';
import { NON_ALPHA_NUM_SLASH_RE } from './shared';
import { getPhoneNumberField } from '../../fields/PhoneNumberField';


const CANDIDATE_COUNTRIES = [
  {
    key: 'US',
    title: 'US',
    description: 'United States of America',
  }, {
    key: 'EU',
    title: 'EU',
    description: 'European Union',
  }, {
    key: 'UK',
    title: 'UK',
    description: 'United Kingdom',
  }, {
    key: 'China',
    title: 'China',
    description: 'Republic of China',
  },
];

export class IpcFormItems extends PureComponent {
  static slug = 'ipc';


  static getSubmissionData(checkData, constituent) {
    return {
      fullName: constituent && constituent.get('fullName'),
      email: (constituent && constituent.get('email')) || (checkData && checkData.email),
      mobileNumber: (constituent && constituent.get('mobileNumber')) || (checkData && checkData.mobileNumber),
      origin: 'doc',
    };
  }

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.replace(NON_ALPHA_NUM_SLASH_RE, '');
  }


  constructor(props) {
    super(props);
    this.state = {
      targetKeys: [],
      selectedKeys: [],
      livedOsGt12months: false,
    };
  }

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
  };

  onCheckboxChange = e => {
    this.setState({ livedOsGt12months: e.target.checked });
  };

  render() {
    const {
      form, disabled, rules, constituent,
    } = this.props;

    const { getFieldDecorator } = form;
    const email = constituent && constituent.get('email');
    const fullName = constituent && constituent.get('fullName');
    const mobileNumber = constituent && constituent.get('mobileNumber');
    const { targetKeys, selectedKeys, livedOsGt12months } = this.state;
    const {
      slug,
    } = this.constructor;

    const countriesLabel = livedOsGt12months ? 'Countries of residence/countries to conduct checks' : 'Countries';
    const additionalCountriesLabel = livedOsGt12months ? 'Additional countries of residence and/or additional countries to check (separate by ; )' : 'Additional countries to check (separate by ; )';

    return (<div>
      { !this.props.isIPCConfigured
        && <div style={{ paddingBottom: '30px' }}>
          <span style={{ color: 'tomato' }}>**Ipc requires configuration for your organization before it can be used. Please contact support@weareoho.com to request enabling.</span>
        </div>
      }
      <Form.Item label="Name">
        { fullName || getFieldDecorator(`${slug}.fullName`, {
          rules: [
            {
              required: true,
              whitespace: true,
              message: 'Full Name is required',
            },
            ...(rules || []),
          ],
        })(
          <Input
            placeholder="Full Name"
            disabled={disabled}
          />,
        )
        }
      </Form.Item>
      <Form.Item label="Email Address">
        { email || getFieldDecorator(`${slug}.email`, {
          rules: [
            {
              type: 'email',
              required: true,
              whitespace: true,
              message: 'Email address is invalid',
            },
            ...(rules || []),
          ],
        })(
          <Input
            placeholder="Email Address"
            disabled={disabled}
          />,
        )
        }
      </Form.Item>
      <Form.Item label="Mobile Number">
        { mobileNumber || getPhoneNumberField({
          fieldName: `${slug}.mobileNumber`,
          fieldText: 'Mobile number',
          rules: [
            {
              required: true,
              whitespace: true,
              message: 'Mobile Number is required',
            },
          ],
          form,
        })}
      </Form.Item>
      <Form.Item style={{ display: 'flex' }} label={<label>Has the person lived overseas for 12 months or more in the last 10 years ?</label>}>
        { getFieldDecorator(`${slug}.livedOsGt12months`, {
          initialValue: false,
        })(
          <Checkbox onChange={this.onCheckboxChange}/>,
        )}
      </Form.Item>
      <Form.Item label={countriesLabel}>
        {getFieldDecorator(`${slug}.countries`, {
          setFieldsValue: selectedKeys,
        })(
          <Transfer
            dataSource={CANDIDATE_COUNTRIES}
            titles={['Candidates', 'Requested']}
            operations = {['Add', 'Remove']}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={this.handleChange}
            onSelectChange={this.handleSelectChange}
            onScroll={this.handleScroll}
            render={item => item.title}
            listStyle={{ width: '200px' }}
          />,
        )}
      </Form.Item>
      <Form.Item label={ additionalCountriesLabel}>
        {getFieldDecorator(`${slug}.additional_countries`, {
          rules: [
            {
              required: false,
              whitespace: true,
            },
          ],
        })(
          <Input
            placeholder='Singapore;South Africa'
            disabled={disabled}
            size="default"
          />,
        )}
      </Form.Item>
      <Form.Item label="Charge Code">
        {getFieldDecorator(`${slug}.charge_code`, {
          rules: [
            {
              required: false,
              whitespace: true,
            },
          ],
        })(
          <Input
            placeholder='Charge code'
            disabled={disabled}
            size="default"
          />,
        )}
      </Form.Item>
    </div>);
  }
}
