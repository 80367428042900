import { Checkbox, DatePicker, Form, Input, Select } from 'antd';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { API_DATE_FS } from './shared';


export class AcadQualFormItems extends PureComponent {
  static slug = 'acadqual';

  constructor(props) {
    super(props);
    this.state = { };
  }

  static getSubmissionData(checkData, constituent) {
    return {
      issued_by: checkData.issued_by,
      course_level: checkData.course_level,
      course_completed: !!checkData.course_completed,
      course_code: checkData.course_code,
      attainment: checkData.attainment.format(API_DATE_FS),
      ...checkData.expiry && { expiry: checkData.expiry.format(API_DATE_FS)},
      fullName: constituent.get('fullName'),
    };
  }

  disablePastDate = current => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  disableAttainmentDate = current => {
    return current && current > moment().endOf('day');
  }

  render() {
    const { form, disabled, rules } = this.props;
    const { getFieldDecorator } = form;
    const { slug } = this.constructor;

    const issuedBy = {
      label: 'Education Provider/ Institution',
      slug: `${slug}.issued_by`,
    };
    const courseLevel = {
      label: 'Course Level',
      slug: `${slug}.course_level`,
    };
    const courseCompleted = {
      label: 'Course Completed',
      slug: `${slug}.course_completed`,
    };
    const courseCode = {
      label: 'Course Code',
      slug: `${slug}.course_code`,
    };
    const dateAttained = {
      label: 'Date Attained',
      slug: `${slug}.attainment`,
    };
    const expiry = {
      label: 'Expiration Date',
      slug: `${slug}.expiry`,
    };

    return (<>
      <Form.Item label={ courseLevel.label }>
        { getFieldDecorator(courseLevel.slug, {
          rules: [
            {
              required: true,
              whitespace: true,
              message: `${courseLevel.label} is required`,
            },
            ...(rules || []),
          ],
        })(
          <Select
            showSearch
            disabled={ disabled }
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
          >
            <Select.Option key="certificate-opt" value="Certificate">Certificate</Select.Option>
            <Select.Option key="diploma-opt" value="Diploma">Diploma</Select.Option>
            <Select.Option key="bachelors-opt" value="Bachelors">Bachelors</Select.Option>
            <Select.Option key="graddip-opt" value="Graduate Diploma">Graduate Diploma</Select.Option>
            <Select.Option key="masters-opt" value="Masters">Masters</Select.Option>
            <Select.Option key="doctoral-opt" value="Doctoral">Doctoral</Select.Option>
          </Select>,
        )
        }
      </Form.Item>
      <Form.Item label={ issuedBy.label }>
        { getFieldDecorator(issuedBy.slug, {
          rules: [
            {
              required: true,
              whitespace: true,
              message: `${issuedBy.label} is required`,
            },
            ...(rules || []),
          ],
        })(
          <Input
            disabled={ disabled }
          />,
        )
        }
      </Form.Item>
      <Form.Item label={ courseCompleted.label }>
        { getFieldDecorator(courseCompleted.slug, {
          rules: [...(rules || [])],
        })(
          <Checkbox
            disabled={ disabled }
          />,
        )
        }
      </Form.Item>
      <Form.Item label={ courseCode.label }>
        { getFieldDecorator(courseCode.slug, {
          rules: [
            {
              required: true,
              whitespace: true,
              message: `${courseCode.label} is required`,
            },
            ...(rules || []),
          ],
        })(
          <Input
            disabled={ disabled }
          />,
        )
        }
      </Form.Item>
      <Form.Item label={ dateAttained.label }>
        {getFieldDecorator(dateAttained.slug, {
          rules: [
            {
              required: true,
              message: `${dateAttained.label} is required`,
            },
            ...(rules || []),
          ],
        })(
          <DatePicker
            disabled={disabled}
            disabledDate={this.disableAttainmentDate}
          />,
        )}
      </Form.Item>
      <Form.Item label={ expiry.label }>
      {getFieldDecorator(expiry.slug, {
          rules: [...(rules || [])],
        })(
          <DatePicker
            disabled={disabled}
            disabledDate={this.disablePastDate}
          />,
        )}
      </Form.Item>
    </>);
  }
}
