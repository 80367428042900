import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { LinkageModal } from './LinkageModal';

class LinkageTileComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showNDWSModal: false };
  }

  toggleNDWSLinkageModal = () => {
    this.setState({ showNDWSModal: !this.state.showNDWSModal });
  }

  startNDWSLinkage = async () => {
    this.props.initiateNDWSLinkage();
  }

  render() {
    const {
      canInitiateLinkage,
      checkNDWSLinkageStatus,
      createClickHandler,
      DashboardTile,
      ndwsLinkageTileCount,
      ndwsLinkageTileStatus,
      postNDWSOtp,
    } = this.props;

    const ndwsTile = {
      label: 'Link NDWS Accreditations',
      onClickHandler: this.toggleNDWSLinkageModal,
      onButtonClick: createClickHandler,
      hoverable: true,
      statsBg: 'rgba(255, 196, 0, 0.2)',
      statsValue: ndwsLinkageTileCount,
      statsValueColor: 'rgb(255, 196, 0)',
    };

    return (
      <>
        {ndwsLinkageTileStatus && DashboardTile(ndwsTile)}
        {this.state.showNDWSModal && <LinkageModal
          canInitiateLinkage={canInitiateLinkage}
          handleCancel={this.toggleNDWSLinkageModal}
          startLinkage={this.startNDWSLinkage}
          handleOTP={postNDWSOtp}
          linkageStatus={checkNDWSLinkageStatus}
          otpPath={'ndws-linkage'}
          title={'NDWS Linkage'}
          visible={this.state.showNDWSModal}
                                     />}
      </>
    );
  }
}
const LinkageTile = connect(
  state => ({
    ndwsLinkageTileCount: state.doc.getIn(['http', 'ndwsLinkageCount', 'data', 'linkage', 'count'], null),
    ndwsLinkageTileStatus: state.doc.getIn(['http', 'ndwsLinkageCount', 'data', 'linkage', 'ndws'], null),
  }),
  actions,
)(LinkageTileComponent);

export { LinkageTile };

