import _ from 'lodash';

import {
  Alert,
  Button,
  Col,
  Form,
  Icon,
  Modal,
  Row,
  Spin,
  Tabs,
  Tooltip,
  message,
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { BreadcrumbLevel } from '../../BreadcrumbLevel';
import { ErrorsList } from '../../ErrorsList';
import * as actions from '../../../actions';
import { checkFeature, parseSearch, stripHints } from '../../../utils';
import { withContentLayout } from '../../layout/Layout';


import { AhpraFormItems } from './ahpra';
import { NtOchreFormItems } from './ntochre';
import { NtTrbFormItems } from './nttrb';
import { QctFormItems } from './qct';
import { QldBlueFormItems } from './qldblue';
import { QldBlueExemptFormItems } from './qldblueex';
import { SaTrbFormItems } from './satrb';
import { SaTrbSpecialFormItems } from './satrbspec';
import { SaWwcFormItems } from './sawwc';
import { TasTrbFormItems } from './tastrb';
import { TasWwcFormItems } from './taswwc';
import { VicWwcFormItems } from './vicwwc';
import { VitFormItems } from './vit';
import { WaTrbFormItems } from './watrb';
import { WaWwcFormItems } from './wawwc';
import { NswWwcFormItems } from './nswwwc';
import { NtWwcFormItems } from './ntwwc';
import { GenAccFormItems } from './genacc';
import { NpcFormItems } from './npc';
import { IpcFormItems } from './ipc';
import { VevoFormItems } from './vevo';
import { NesaFormItems } from './nesa';
import { UsiFormItems } from './usi';
import { AcadQualFormItems } from './acadqual';

const { TabPane } = Tabs;
const GENACC = '_GENACC';
const NSWWWC = 'nswwwc';
const SAWWC = 'sawwc';
const NPC = 'npc';
const IPC = 'ipc';
const VICWWC = 'vicwwc';
const WWCS = 'wwcs';
const GENERAL = 'general';
const OURTYPES = 'our types';

const CHECK_TYPE_FORMS = [
  // Enabled/Visible tab MUST come first
  NswWwcFormItems,
  VicWwcFormItems,
  VitFormItems,
  AhpraFormItems,
  NtOchreFormItems,
  NtTrbFormItems,
  QctFormItems,
  QldBlueFormItems,
  QldBlueExemptFormItems,
  SaTrbFormItems,
  SaTrbSpecialFormItems,
  SaWwcFormItems,
  TasTrbFormItems,
  TasWwcFormItems,
  WaTrbFormItems,
  WaWwcFormItems,
  NtWwcFormItems,
  NpcFormItems,
  IpcFormItems,
  VevoFormItems,
  GenAccFormItems,
  NesaFormItems,
  UsiFormItems,
  AcadQualFormItems,
];
const CHECK_TYPE_MAP = _.fromPairs(CHECK_TYPE_FORMS.map(
  comp => [comp.slug, comp],
));
const CHECK_TYPE_ENABLED = _.fromPairs(CHECK_TYPE_FORMS.map(
  comp => [comp.slug, checkFeature(`${comp.slug}Scanner`)],
));

const checkDefineTypeValidity = type => new Date(type.effectiveTo) >= Date.now();


const groupByTab = tabList => tabList && tabList.length > 0 && tabList.reduce((r, a) => {
  // Ignoring expired define types
  if (a.displayTab === _.startCase(OURTYPES) && !checkDefineTypeValidity(a)) return r;
  r[a.displayTab] = [...r[a.displayTab] || [], a];
  return r;
}, {});

const prePareScanTypes = scanTypes => {
  const scanTypesJs = scanTypes && scanTypes.toObject ? scanTypes.toJS() : [];
  const itemsToGroupBy = groupByTab(scanTypesJs);
  const displayTabs = Object.keys(itemsToGroupBy).sort().reverse();
  return { scanTypesJs, itemsToGroupBy, displayTabs };
};

const getAccredName = (scanTypes, type) => {
  const scanTypesJs = scanTypes && scanTypes.toObject ? scanTypes.toJS() : [];
  const itemFound = scanTypesJs.find(item => item.typeCode === type);
  const label = (itemFound && itemFound.typeName) || '';
  return label;
};

const isGenAccred = checkType => {
  if (checkType.indexOf(GENACC) > -1) {
    return true;
  }
  return false;
};

const getScannerGroup = (lowerCasedGroup, scanner) => {
  let isRealScanner = true;
  let isGenAcc = false;
  switch (lowerCasedGroup) {
    case GENERAL:
    case OURTYPES:
      isGenAcc = true;
      break;
    default:
      if (!scanner) isRealScanner = false;
  }
  if (!isGenAcc && !isRealScanner) isRealScanner = true;
  return { isRealScanner, isGenAcc };
};

const setDefaultActiveTab = (lowerCasedGroup, aDisplayGroup) => {
  let defaultActive;
  switch (lowerCasedGroup) {
    case WWCS: defaultActive = VICWWC;
      break;
    case GENERAL:
    case OURTYPES:
      defaultActive = `${aDisplayGroup[0].typeCode}${GENACC}`;
      break;
    default:
      defaultActive = aDisplayGroup[0].typeCode;
  }
  return defaultActive;
};

function OrganizationInactiveModal(props) {
  return (
    <Modal
      title="Function not available"
      visible={true}
      footer={false}
      closable={false}
      zIndex={99}
    >
      <p>
        This function is not currently available, as your organization is inactive.
      </p>
      <p>
        Please <Link to="/activate">activate</Link> your subscription to continue
      </p>
    </Modal>);
}

function EmailNotVerifiedModal(props) {
  return (
    <Modal
      title="Feature not available"
      visible={true}
      footer={false}
      closable={false}
      zIndex={99}
    >
      <p>
        This feature is not currently available, as your email is not verified.
      </p>
      <p>
        Please verify your email to continue.
      </p>
    </Modal>);
}

@withContentLayout
class ConstituentScanPageComponent extends PureComponent {
  static state = {
    checkType: VICWWC,
    defaultConstituentName: null,
    defaultConstituentId: null,
    inProgress: false,
    backendErrors: {},
    isNSWConfigured: true,
    isSAConfigured: true,
    isNPCConfigured: true,
    isIPCConfigured: true,
    nowInGroup: WWCS,
    groupsClicked: 0,
    [WWCS]: VICWWC,
  }

  constructor(props) {
    super(props);
    this.state = { ...ConstituentScanPageComponent.state };
    this.buildTagHash = tag => (tag.with_ ? '' : '!') + (tag.role ? tag.role : tag.id);
  }

  componentDidMount() {
    this.props.setConstituentId(this.props.constituentId);
    this.props.fetchOrganization(this.props.organizationId).then(() => {
      const currentToJSON = this.props.institutionsCredentials && this.props.institutionsCredentials.toJSON();
      const providerSettings = this.props.providerSettings && this.props.providerSettings.toJSON();
      const { lastCheckedStatus: currentOCGStatus } = (currentToJSON && currentToJSON.nsw) || {};
      const { enabled: currentOneTimeCheckStatus } = (currentToJSON && currentToJSON.nswOneTimeCheck) || {};
      const { lastCheckedStatus: currentSAStatus } = (currentToJSON && currentToJSON.sa) || {};
      const { paperworkSignedTs: currentNPCStatus } = (providerSettings && providerSettings.npc) || {};
      const { enabled: currentIPCStatus } = (currentToJSON && currentToJSON.ipc) || {};
      const orgSettingsToJSON = (this.props.settings && this.props.settings.toJSON()) || {};
      const orgMode = orgSettingsToJSON && orgSettingsToJSON.orgMode === 'oneoff' ? 'oneoff' : 'ongoing';

      if ((orgMode === 'oneoff' && !currentOneTimeCheckStatus) || (orgMode === 'ongoing' && currentOCGStatus !== 'success')) {
        this.setState({ isNSWConfigured: false });
      }
      if (!currentSAStatus
        || currentSAStatus !== 'success'
      ) {
        this.setState({ isSAConfigured: false });
      }
      if (!currentNPCStatus) {
        this.setState({ isNPCConfigured: false });
      }
      if (!currentIPCStatus) {
        this.setState({ isIPCConfigured: false });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.constituentId !== this.props.constituentId) {
      nextProps.setConstituentId(nextProps.constituentId);
    }
    if (
      !nextProps.constituent
      && !nextProps.getConstituentFailed
      && !nextProps.getConstituentInProgress
    ) this.props.getConstituent(nextProps.constituentId);

    if (nextProps.getConstituentFailed) message.error("That person wasn't found");

    if (nextProps.submitScanData && nextProps.submitScanData !== this.props.submitScanData) {
      const submitScanData = nextProps.submitScanData.toJS();
      if (submitScanData._errors) this.setBackendErrors(nextProps, submitScanData._errors);
    }
  }

  getConstituentId = (props = this.props) => parseInt(parseSearch(props.location.search).constituentId, 10)

  setBackendErrors = (nextProps, errors) => {
    this.backendErrors = errors;
    this.backendErrorsLock = true;
    nextProps.form.validateFields({ force: true }, () => {
      this.backendErrorsLock = false;
    });
  }

  handleGroup = val => {
    const { itemsToGroupBy } = prePareScanTypes(this.props.scanTypes);
    let checkedVal = itemsToGroupBy && itemsToGroupBy[val] && itemsToGroupBy[val][0] && itemsToGroupBy[val][0].typeCode;
    val = val.toLocaleLowerCase();
    if (val === GENERAL || val === OURTYPES) {
      checkedVal += GENACC;
    }
    if (val === WWCS && checkedVal === itemsToGroupBy.WWCs[0]) {
      checkedVal = VICWWC;
    }
    if (this.state[val]) {
      checkedVal = this.state[val];
    }
    this.setState({ checkType: checkedVal, [val]: checkedVal, groupsClicked: this.state.groupsClicked + 1 });
    // this.forceUpdate();
  }

  handleTypeChange = (checkType, group) => {
    this.setState({ checkType, [group]: checkType, groupsClicked: this.state.groupsClicked + 1 });
  }

  handleSubmit = evt => {
    evt.preventDefault();
    this.setState({ backendErrors: [] });
    this.props.form.validateFields(async (errors, values) => {
      if (errors) return;

      const { constituent, constituentId } = this.props;
      const { checkType } = this.state;
      let checkData;
      let submissionData;
      let isGenAcc = false;

      let checkTypeName = checkType;

      // Check if the child component has the showWarnings method
      const showWarnings = _.get(CHECK_TYPE_MAP, [checkType, 'showWarnings']);
      if (typeof showWarnings === 'function') {
        const confirmation = await showWarnings({ constituent });
        if (confirmation !== 'OK') {
          return;
        }
      }


      if (checkType.indexOf(GENACC) > -1) {
        isGenAcc = true;
        checkData = values[checkType] || values.genaccIntl;
        submissionData = GenAccFormItems.getSubmissionData(
          checkData, constituent,
        );
        [checkTypeName] = checkType.split(GENACC);
      } else {
        checkData = values[checkType];
        submissionData = CHECK_TYPE_MAP[checkType].getSubmissionData(
          checkData, constituent,
        );
      }

      const fullData = {
        constituent: {
          id: constituentId,
        },
        ...checkData,
        ...submissionData,
      };

      this.setState({ inProgress: true });
      const isNewDoB = fullData && fullData.isNew;
      let patchedConstituent = null;
      if (isNewDoB) {
        try {
          patchedConstituent = Promise.resolve(this.props.patchConstituent(constituentId, { birth_date: fullData && fullData.birth_date }));
        } catch (e) {
          this.setBackendErrors(this.props, e);
          this.setState({ inProgress: false, e });
          return;
        }
      }

      if (!isNewDoB || patchedConstituent) {
        this.props.submitScan(
          checkType,
          fullData,
        )
          .then(data => {
            if (data._errors) {
              this.setBackendErrors(this.props, data._errors);
              this.setState({ inProgress: false, backendErrors: data._errors });
              return;
            }

            if (checkType === 'vevo' || (data.status.found && data.status.current)) {
              this.props.setConstituentAccreditationIdAction(fullData && fullData.identifier, constituentId, isGenAcc ? 'genacc' : checkType);
              if (data._warnings) message.warning(data._warnings);
              else message.success(`Added ${getAccredName(this.props.scanTypes, checkTypeName)}`);
              this.props.history.push(`/constituents/${fullData.constituent.id}/accreditations`);
              return;
            }

            let messages = [];
            if (data.status.messages) {
              messages = data.status.messages;
            } else if (data.status.found) {
              messages = ['The provided details are not current'];
            } else {
              messages = ['The provided details could not be verified'];
            }

            this.setBackendErrors(this.props, { search: messages });
            this.setState({ inProgress: false, backendErrors: { search: messages } });
          })
          .catch(data => {
            this.setBackendErrors(this.props, data._errors);
            this.setState({ inProgress: false, backendErrors: data._errors });
          });
      } else {
        this.setBackendErrors(this.props, []);
        this.setState({ inProgress: false, backendErrors: [] });
      }
    });
  }

  backendErrorsValidator = (rule, value, callback) => {
    if (!this.backendErrors) {
      callback([]);
      return;
    }
    const backendErrors = this.backendErrors.toObject
      ? this.backendErrors.toObject() : this.backendErrors;
    callback(Object.values(backendErrors).reduce(
      (big, small) => [...big, ...(small.toArray ? small.toArray() : small)], [],
    ));
    if (this.backendErrorsLock) return;
    this.backendErrors = null;
  }

  buildLocationFromHash(query) {
    if (!query) return [];
    const queryparams = query.split('?')[1];
    const params = queryparams.split('&');
    let pair = null;
    const data = {};
    params.forEach(d => {
      pair = d.split('=');
      const key = pair[0];
      const value = pair[1];
      switch (key) {
        case 'order':
          // eslint-disable-next-line no-case-declarations
          const [sortBy, sortOrder] = value.split('%2C');
          data.sortBy = sortBy;
          data.sortOrder = sortOrder;
          break;
        case 'with_tag_ids':
          // eslint-disable-next-line no-case-declarations
          const withTagParts = value.split('%2C').filter(v => v);
          data[key] = withTagParts.map(t => ({ id: Number(t), with_: true }));
          break;
        case 'without_tag_ids':
          // eslint-disable-next-line no-case-declarations
          const withoutTagParts = value.split('%2C').filter(v => v);
          data[key] = withoutTagParts.map(t => ({ id: Number(t), with_: false }));
          break;
        default:
          data[key] = value;
      }
    });
    return data;
  }

  getPreviousFilterHash = () => {
    const data = this.buildLocationFromHash(this.props.constituentFilterHash);
    const withTags = data.with_tag_ids || [];
    const tags = withTags.concat(data.without_tag_ids || []);
    const previousLocationHash = this.buildPreviousLocationHash({ ...data, tags });
    return `#filters=${previousLocationHash.filters.join('%2C')}`;
  }

  buildPreviousLocationHash = state => {
    const filteredTags = [];
    if (this.props.tags) {
      const tags = this.props.tags.toJS();
      tags.forEach(tag => {
        const tempTag = state.tags.find(tt => tt.id === tag.id);
        if (tempTag) {
          filteredTags.push(this.buildTagHash({ ...tag, ...tempTag }));
        }
      });
    }
    return ({ filters: filteredTags });
  };

  render() {
    const {
      constituent,
      constituentId,
      form,
      scanTypes,
      organizationIsActive,
      emailVerified,
      institutionsCredentials,
      providerSettings,
    } = this.props;
    const {
      checkType,
      inProgress,
      backendErrors,
      isNSWConfigured,
      isSAConfigured,
      isNPCConfigured,
      isIPCConfigured,
    } = this.state;

    const scanTypesJs = scanTypes && scanTypes.toObject ? scanTypes.toJS() : [];
    const itemsToGroupBy = groupByTab(scanTypesJs);
    const displayTabs = Object.keys(itemsToGroupBy).sort().reverse();
    const displayName = constituent && stripHints(constituent.get('fullName'));
    const institutionsCredSettings = institutionsCredentials && institutionsCredentials.toJSON();
    const providerSettingsData = providerSettings && providerSettings.toJSON();
    const prevFilters = this.getPreviousFilterHash() || '';

    return (<div>
      <BreadcrumbLevel text="Add accreditation" />
      { constituent && <BreadcrumbLevel text={displayName} />}

      { emailVerified || <EmailNotVerifiedModal /> }

      { organizationIsActive || <OrganizationInactiveModal />}

      <Form onSubmit={this.handleSubmit}>
        { /* 24 is Form.Item bottom padding */}
        { /* TODO make this look better without the constituent drop-down */}
        <div style={{
          marginLeft: '16px',
          marginBottom: `${24 + 8}px`,
          display: 'flex',
          justifyContent: 'space-between',
        }}
        >
          <Spin spinning={!constituent}>
            <h2>{constituent ? displayName : 'Loading'}</h2>
          </Spin>
          <Link to={`/constituents/${constituentId}/accreditations${prevFilters}`}>
            Open person settings
          </Link>
        </div>
        {backendErrors && <ErrorsList errors={backendErrors} />}
        <Tabs tabPosition="top" defaultActiveKey="WWCs" size="large" onChange={this.handleGroup}>
          {displayTabs && displayTabs.length > 0 && displayTabs.map((item, idx) => {
            const aDisplayGroup = itemsToGroupBy[item];
            const lowerCasedItem = item.toLocaleLowerCase();
            const defaultActive = setDefaultActiveTab(lowerCasedItem, aDisplayGroup);
            return (<TabPane tab={item} key={item}>
              <Tabs
                tabPosition="left"
                defaultActiveKey={defaultActive}
                onChange={e => this.handleTypeChange(e, item.toLowerCase())}
              >
                {
                  aDisplayGroup && aDisplayGroup.length > 0 && aDisplayGroup.map(accredDefinition => {
                    const { isRealScanner, isGenAcc: isGenAccGroup } = getScannerGroup(lowerCasedItem, accredDefinition.scanner);
                    let { ScanTypeComponent, slug } = !isGenAccGroup && !isRealScanner ? { slug: null, ScanTypeComponent: null } : { slug: `${accredDefinition.typeCode}${GENACC}`, ScanTypeComponent: GenAccFormItems };
                    let enabledSlug = false;
                    const label = accredDefinition.typeName;
                    const itemFound = CHECK_TYPE_FORMS.find(formType => formType.slug === accredDefinition.typeCode);

                    if (itemFound) {
                      ScanTypeComponent = itemFound;
                      slug = itemFound.slug;
                      enabledSlug = true;
                    }

                    const tabValid = slug && CHECK_TYPE_ENABLED[slug];
                    const tab = (tabValid || (slug && slug.indexOf(GENACC) > -1))
                      ? label
                      : (<Tooltip title="Validator experiencing issues">
                        { label}
                        <Icon
                          type="warning"
                          style={{ paddingLeft: '14px', paddingRight: 0 }}
                        />
                      </Tooltip>);
                    return slug ? (<TabPane tab={tab} key={slug}>
                      {checkType === slug
                        && (
                          <div>
                            {enabledSlug
                              && !CHECK_TYPE_ENABLED[slug]
                              && (
                                <Alert
                                  message="Sorry, the validator for this accreditation is currently unavailable"
                                  type="error"
                                />)
                            }
                            <ScanTypeComponent
                              form={form}
                              // groupsClicked={this.state.groupsClicked}
                              disabled={inProgress}
                              constituent={constituent}
                              isNSWConfigured={isNSWConfigured}
                              isSAConfigured={isSAConfigured}
                              isNPCConfigured={isNPCConfigured}
                              isIPCConfigured={isIPCConfigured}
                              genAccredSlug={!enabledSlug && slug}
                              accredDefinition={accredDefinition}
                              institutionsCredSettings={institutionsCredSettings}
                              providerSettings={providerSettingsData}
                            />
                          </div>)}
                    </TabPane>) : null;
                  })
                }
                <Row key={idx} justify="end" type="flex">
                  <Col>
                    <Spin spinning={!constituent} tip="Loading person">
                      <Button
                        style={{ marginTop: '16px' }}
                        loading={inProgress}
                        type="primary"
                        htmlType="submit"
                        size="large"
                        disabled={!isGenAccred(checkType) && (!CHECK_TYPE_ENABLED[checkType] || !constituent || (checkType === NSWWWC && !isNSWConfigured) || (checkType === SAWWC && !isSAConfigured) || (checkType === NPC && !isNPCConfigured) || (checkType === IPC && !isIPCConfigured))}
                      >Add Accreditation</Button>
                    </Spin>
                  </Col>
                </Row>
              </Tabs>
            </TabPane>);
          })
          }
        </Tabs>
      </Form>
    </div>);
  }
}

const ConstituentScanPageForm = Form.create()(ConstituentScanPageComponent);

const ConstituentScanComponent = withRouter(connect(
  (state, ownProps) => ({
    submitScanData: state.doc.getIn(['http', 'submitScan', 'data'], null),
    submitScanInProgress: state.doc.getIn(['http', 'submitScan', 'inProgress'], false),
    organizationId: state.doc.getIn(['authMr', 'constituent', 'organization', 'id'], null),
    organizationIsActive: state.doc.getIn(['authMr', 'constituent', 'organization', 'isActive'], false),
    emailVerified: state.doc.getIn(['authMr', 'emailVerified'], false),
    constituent: state.doc.getIn(['constituentsMr', 'byIdFull', ownProps.constituentId], null),
    getConstituentInProgress: state.doc.getIn(['http', 'getConstituent', 'inProgress']),
    getConstituentFailed: state.doc.getIn(['http', 'getConstituent', 'failed']),
    patchConstituent: state.doc.getIn(['http', 'patchConstituent', 'data']),
    patchConstituentInProgress: state.doc.getIn(['http', 'patchConstituent', 'inProgress']),
    patchConstituentFailed: state.doc.getIn(['http', 'patchConstituent', 'failed']),
    scanTypes: state.doc.getIn(['http', 'scanTypes', 'data'], {}),
    institutionsCredentials: state.doc.getIn(['authMr', 'constituent', 'organization', 'institutionsCredentials'], null),
    providerSettings: state.doc.getIn(['authMr', 'constituent', 'organization', 'settings', 'providers'], null),
    constituentFilterHash: state.doc.getIn(['constituentsMr', 'lists', 'constituentsPage', 'filterHash'], null),
    tags: state.doc.getIn(['http', 'fetchTags', 'data', 'items'], null),
    settings: state.doc.getIn(['http', 'fetchOrganization', 'data', 'settings'], null),
  }),
  actions,
)(ConstituentScanPageForm));

const constituentFromRoute = props => {
  if (props.match.params.id) {
    return parseInt(props.match.params.id, 10);
  }
  return parseInt(parseSearch(props.location.search).constituentId, 10);
};

export const ConstituentScanPage = props => (
  <ConstituentScanComponent constituentId={constituentFromRoute(props)} {...props} />
);
