import {
  Alert, Button, Card, Col, Empty, Icon, Row, Spin, Statistic, Typography, Collapse, Tag, Tooltip,
} from 'antd';
import qs from 'querystringify';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import * as actions from '../../actions';
import { checkFeature } from '../../utils';
import TwoFactorModalComponent from './TwoFactorModalComponent';
import { DEFAULT_QLDBLUE_LINKAGE_POLLING_DELAY, QLDBLUE_LINKAGE_INITIATED_POLLING_DELAY } from '../../defaults';
import { LinkageTile } from '../fields/LinkageTile';

const { Title } = Typography;


const COLORS_MAP = {
  green: [39, 150, 3],
  yellow: [255, 196, 0],
  red: [255, 38, 14],
  cyan: [19, 194, 194],
};


export function DashboardTile(props) {
  return (
    <Card
      style={{
        marginRight: '16px', marginBottom: '16px', borderRadius: '20px', alignContent: 'center', width: '180px', height: 'auto',
      }}
      onClick={props.onClickHandler}
      hoverable={!!props.hoverable}
    >
      <div style={{ display: 'block', height: '80px' }}>
        <Title level={4} style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center' }}>{props.label}</Title>
      </div>
      <div style={{
        display: 'flex', backgroundColor: props.statsBg, height: '125px', borderRadius: '50%', justifyContent: 'center',
      }}
      >
        <Statistic
          style={{
            display: 'flex', alignItems: 'center',
          }}
          value={props.statsValue}
          valueStyle={{ color: props.statsValueColor }}
        />
      </div>
      <Button style={{ display: 'block', margin: '20px auto 5px auto' }} onClick={props.onButtonClick} >View status</Button>
    </Card>
  );
}

function LatestUpdatesSection(props) {
  const { loading, failed } = props;
  const { constituentCount, claimCount, checkCount, statusChangesCount } = props.stats;
  return (
    <div>
      { failed ? <></>
        : <Card loading={loading} bordered={true} style={{ width: 400, borderRadius: '6px', borderColor: '#cdb3ab', backgroundColor: '#f5ece4' }}>
          <div>
            <span style={{ fontWeight: '600', fontSize: '14px', color: '#212721' }}>Latest updates </span>
            <div style={{ marginTop: '14px' }}>
              <p><b style={{ color: '#82412C' }}>{constituentCount} new {constituentCount > 1 ? 'people' : 'person'} added</b> to the system</p>
              <p><b style={{ color: '#82412C' }}>{claimCount} new accreditations added</b> to your people</p>
              <p><b style={{ color: '#82412C' }}>{checkCount} checks</b> completed by Oho since last login</p>
              <p><b style={{ color: '#82412C' }}>{statusChangesCount} accreditation status changes</b> since last login</p>
            </div>
          </div>
        </Card> }
    </div>
  );
}

@connect(state => ({
  httpOrgStatsData: state.doc.getIn(['http', 'orgStats', 'data'], null),
  httpOrgStatsFailed: state.doc.getIn(['http', 'orgStats', 'failed'], false),
  httpOrgStatsInProgress: state.doc.getIn(['http', 'orgStats', 'inProgress'], true),
}), actions)
class UserSummarySection extends PureComponent {
  render() {
    const {
      lastLoggedInTs, authUserFullName, httpOrgStatsData, httpOrgStatsInProgress, httpOrgStatsFailed,
    } = this.props;
    const httpOrgStatsDataJs = httpOrgStatsData ? httpOrgStatsData.toJSON() : {};
    return (
      <Row type="flex" justify="center" style={{ backgroundColor: '#f5ece4' }}>
        <Col xs={24} md={12} style={{ padding: '5%' }}>
          <div>
            <span style={{ fontSize: '34px', fontWeight: 'normal', color: '#82412C' }}>Welcome Back,<br/> {authUserFullName.split(' ')[0]}</span>
            { lastLoggedInTs && <p>Last log in {moment(lastLoggedInTs).format('LLL')}</p>}
          </div>
        </Col>
        <Col xs={24} md={12} style={{ padding: '5%' }}>
          { checkFeature('orgStats') && <LatestUpdatesSection loading={httpOrgStatsInProgress} failed={httpOrgStatsFailed} stats={httpOrgStatsDataJs} /> }
        </Col>
      </Row>
    );
  }
}

function StatsCard(props) {
  return (
    <div style={{ backgroundColor: '#f5ece4', padding: '30px', marginTop: '30px' }}>
      <div style={{ display: 'flex' }}>
        <Statistic valueStyle={{ color: '#82412C' }} value={props.value}/>
        <div style={{ width: '320px', paddingLeft: '30px', wordWrap: 'break-word' }}>
          {props.description}
        </div>
      </div>
    </div>
  );
}

const customPanelStyle = {
  background: '#dbe4e9',
  border: 0,
  fontSize: '30px',
  overflow: 'hidden',
  // marginLeft:'-18px',
  paddingLeft: '4%',
  paddingTop: '3%',
  paddingBottom: '2%',
};

function getObjectFromLocalstorage(key, defaultValue) {
  const value = localStorage.getItem(key);
  if (value === null) {
    return defaultValue;
  }
  return JSON.parse(value);
}


class AccreditationsSummarySectionComponent extends PureComponent {
  state = {
    qldblueLinkageModalVisible: false,
    qldblueLinkageStatus: null,
    qldblueLinkageOTPSubmitted: null,
    qldblueLinkageUpdates: null,
    qldbluePollingDelay: DEFAULT_QLDBLUE_LINKAGE_POLLING_DELAY,
    oneTimePasswordQldLoading: false,
    ndwsLinkageModalVisible: false,

    dashboardImportantCardPanelToggle: getObjectFromLocalstorage('dashboardImportantCardPanelToggle', true),
    dashboardCloserToCompliancePanelToggle: getObjectFromLocalstorage('dashboardCloserToCompliancePanelToggle', false),
    dashboardStatsVisualPanelToggle: getObjectFromLocalstorage('dashboardStatsVisualPanelToggle', false),
    dashboardSystemStatsPanelToggle: getObjectFromLocalstorage('dashboardSystemStatsPanelToggle', false),
  }

  static cards = [
    {
      label: 'Compliant',
      key: 'okay',
      color: 'green',
      tags: [
        'smart.active.active',
        'smart.accreditation',
        '!smart.accreditation_invalid',
      ],
      orgTags: [
        'smart.organization.',
      ],
    },
    {
      label: 'Due to Expire',
      key: 'expiring42',
      color: 'yellow',
      tags: ['smart.active.active', 'smart.accreditation_expiring'],
    },
    {
      label: 'New card available',
      key: 'vicwwcNewCard',
      color: 'yellow',
      tags: ['smart.active.active', 'smart.accreditation.vicwwc.is_new_card'],
    },
    {
      label: 'Application In Progress',
      key: 'isApplication',
      color: 'yellow',
      tags: ['smart.active.active', 'smart.accreditation_is_application'],
    },
    {
      label: 'Expired',
      key: 'expired',
      color: 'red',
      tags: ['smart.active.active', 'smart.accreditation_invalid.expired'],
    },
    {
      label: 'Invalid',
      key: 'invalid',
      color: 'red',
      tags: ['smart.active.active', 'smart.accreditation_invalid'],
    },
    {
      label: 'In Progress',
      key: 'inProgress',
      color: 'red',
      tags: ['smart.active.active', 'smart.accreditation_in_progress'],
    },
    {
      label: 'Review Required',
      key: 'reviewRequired',
      color: 'red',
      tags: ['smart.active.active', 'smart.accreditation_review_required'],
    },
    {
      label: 'Missing Information',
      key: 'missingDobInformation',
      color: 'red',
      tags: ['smart.active.active', 'smart.accreditation_missing_dob_information'],
    },
    {
      label: 'People Without Accreditations',
      key: 'zeroAccreds',
      color: 'cyan',
      tags: ['smart.active.active', '!smart.accreditation'],
      orgTags: [
        'smart.organization.',
      ],
    },
  ]

  static importantCards = [
    'invalid', 'expired', 'zeroAccreds', 'missingDobInformation', 'reviewRequired',
  ]

  static qldLinkageCard = {
    key: 'qldLinkage',
    tags: ['smart.active.active', 'smart.birth_date.given', 'smart.accreditation.qldblueall', 'smart.accreditation_not_linked'],
    orgTags: ['smart.organization.'],
  }

  static ndwsLinkageCard = {
    key: 'ndwsLinkage',
    tags: ['smart.active.active', 'smart.accreditation.ndws', 'smart.accreditation_not_linked'],
    orgTags: ['smart.organization.'],
  }

  cacheDashboardPanelToggle() {
    window.localStorage.setItem('dashboardImportantCardPanelToggle', this.state.dashboardImportantCardPanelToggle);
    window.localStorage.setItem('dashboardCloserToCompliancePanelToggle', this.state.dashboardCloserToCompliancePanelToggle);
    window.localStorage.setItem('dashboardStatsVisualPanelToggle', this.state.dashboardStatsVisualPanelToggle);
    window.localStorage.setItem('dashboardSystemStatsPanelToggle', this.state.dashboardSystemStatsPanelToggle);
  }

  componentWillMount() {
    this.props.fetchCurrentOrganization();
    if (checkFeature('qldLinking')) {
      this.props.fetchQldBlueLinkageTileStatus();
      this.getQldBlueLinkageStatus();
      this.props.fetchTwoFactorCode();
    }
    if (checkFeature('ndwsLinkage')) {
      this.props.fetchNDWSLinkageTileStatus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (checkFeature('qldLinking')) {
      if (prevState.qldbluePollingDelay !== this.state.qldbluePollingDelay) {
        clearInterval(this.qldbluePoller);
        this.getQldBlueLinkageStatus();
      }
    }
  }

  componentWillUnmount() {
    if (checkFeature('qldLinking')) {
      clearInterval(this.qldbluePoller);
    }
    this.cacheDashboardPanelToggle();
  }

  createClickHandler = ({ tags, orgSmart, dashboardType, organizationId }) => ev => {
    const orgSmartTag = orgSmart ? `${orgSmart[0]}${this.props.orgId}` : null;
    const tempTags = tags.slice();
    if (dashboardType === 'zeroAccreds') tempTags.push(`!${orgSmartTag}`);
    const qsData = { filters: tempTags.join(','), orgSmartTag, dashboardType, organizationId };
    this.props.history.push(`/constituents#${qs.stringify(qsData)}`);
  }

  generatedOrgRelationshipSummary = summary => {
    const cardComponents = [];
    const { cards, importantCards } = AccreditationsSummarySectionComponent;
    const relatedCards = ['invalid', 'expired', 'zeroAccreds'];
    relatedCards.sort((a, b) => {
      if (importantCards.indexOf(a) > importantCards.indexOf(b)) return 1;
      if (importantCards.indexOf(a) < importantCards.indexOf(b)) return -1;
      return 0;
    });
    // eslint-disable-next-line no-restricted-syntax
    relatedCards.forEach((relatedCard, idx) => {
      const extraInfo = cards.find(card => card.key === relatedCard);
      cardComponents.push(<Col key={idx}>
        <DashboardTile label={extraInfo.label} hoverable={!!summary.organization.viewable} onClickHandler={summary.organization.viewable ? this.createClickHandler({ tags: extraInfo.tags, orgSmart: extraInfo.orgTags, dashboardType: extraInfo.key, organizationId: summary.organization.id }) : () => null} statsBg={`rgba(${COLORS_MAP[extraInfo.color].join(',')}, 0.2)`} statsValue={summary[relatedCard]} statsValueColor={`rgb(${COLORS_MAP[extraInfo.color].join(',')})`}/>
      </Col>);
    });
    return cardComponents;
  }

  getNDWSCanLink = () => {
    if (!this.props.settings) return false;
    const { providers } = this.props.settings.toJSON() || {};
    const { ndws } = providers || {};
    if (!ndws) return true;
    return !ndws.ohoManagedCreds;
  };

  // TODO: have this check for linkage functionality to be similar to the ndws linkage tile
  getQldBlueLinkageStatus = () => {
    this.props.fetchQldBlueLinkageStatus();
    this.qldbluePoller = setInterval(() => this.props.fetchQldBlueLinkageStatus(), this.state.qldbluePollingDelay);
  };

  handlePanelToggleChange(activeKey, panelKey) {
    if (Array.isArray(activeKey) && activeKey.length) {
      activeKey.forEach(key => {
        this.setState({ [key]: true });
      });
    } else {
      this.setState({ [panelKey]: false });
    }
  }

  render() {
    const {
      currentOrganizationId,
      httpData,
      httpFailed,
      httpInProgress,
      initiateQldBlueLinkage,
      otpPreference,
      qldblueLinkageError,
      showRelationshipAccredSummary,
      qldblueLinkageResult,
      qldblueLinkageStatus,
      qldblueLinkageOTPSubmitted,
      qldblueLinkageUpdates,
      qldblueLinkageTileCount,
    } = this.props;

    if (httpInProgress) return <Spin />;
    if (qldblueLinkageResult) {
      if (this.state.qldblueLinkageStatus !== qldblueLinkageStatus) {
        this.setState({ qldblueLinkageStatus });
      }
      if (this.state.qldblueLinkageOTPSubmitted !== qldblueLinkageOTPSubmitted) {
        this.setState({ qldblueLinkageOTPSubmitted });
      }
      if (this.state.qldblueLinkageUpdates !== qldblueLinkageUpdates) {
        this.setState({ qldblueLinkageUpdates });
      }
    }

    const httpDataJs = httpData ? httpData.toJSON() : {};
    const httpDataJsKeys = Object.keys(httpDataJs);
    if (httpDataJsKeys.length === 0 || httpDataJsKeys[0] === '_errors') return null;
    const mainOrgDashboard = httpDataJs.find(dashboard => dashboard.organization.id === currentOrganizationId);
    const relatedOrgDashboards = httpDataJs.filter(dashboard => dashboard.organization.id !== currentOrganizationId);
    const { cards, importantCards, qldLinkageCard, ndwsLinkageCard } = AccreditationsSummarySectionComponent;

    function percentage(partialValue, totalValue) {
      return (partialValue) / totalValue;
    }

    const actionCards = (
      cards
        .filter(({ key }) => {
          if (importantCards.indexOf(key) !== -1) {
            if (mainOrgDashboard) {
              const thisData = mainOrgDashboard[key];
              if (thisData && thisData > 0) {
                return true;
              }
            }
          }
          if (!mainOrgDashboard) return false;
          return false;
        })
    );

    const actionCardsIssueCount = (actionCards.reduce((acc, { key, color }) => {
      if (color === 'red' && mainOrgDashboard[key]) {
        return acc + mainOrgDashboard[key];
      }
      return acc;
    }, 0));

    const compliantCard = (
      cards.find(({ key }) => key === 'okay')
    );

    const invalidCard = (
      cards.find(({ key }) => key === 'invalid')
    );

    let compliantPercentage;
    let invalidPercentage;
    if (mainOrgDashboard) {
      const { total } = mainOrgDashboard;
      compliantPercentage = percentage(mainOrgDashboard[compliantCard.key], total);
      invalidPercentage = percentage(mainOrgDashboard[invalidCard.key], total);
    }

    const closeToComplianceCards = (
      cards
        .filter(({ key }) => {
          if (importantCards.indexOf(key) !== -1) {
            return false;
          }
          if (!mainOrgDashboard) return false;
          const thisData = mainOrgDashboard[key];
          if (thisData && thisData > 0) {
            return true;
          }
          return false;
        })
    );

    const closeToComplianceCardsIssueCount = (closeToComplianceCards.reduce((acc, { key, color }) => {
      if (color === 'red' && mainOrgDashboard[key]) {
        return acc + mainOrgDashboard[key];
      }
      return acc;
    }, 0));

    const toggleLinkingModal = () => {
      this.setState({
        qldblueLinkageModalVisible: !this.state.qldblueLinkageModalVisible,
      });
    };

    const initiateLinkage = () => {
      initiateQldBlueLinkage();
      this.setState({ oneTimePasswordQldLoading: true });
      this.props.fetchQldBlueLinkageStatus();
    };

    const resetPollingDelay = () => {
      this.setState({ qldbluePollingDelay: DEFAULT_QLDBLUE_LINKAGE_POLLING_DELAY });
    };

    const startLinking = async linkType => {
      this.setState({ linkType });
      if (otpPreference === 'manual') {
        toggleLinkingModal();
        this.setState({ qldbluePollingDelay: QLDBLUE_LINKAGE_INITIATED_POLLING_DELAY });
      }
    };

    const handleQldBlueOTP = async twoFactorCodeForm => {
      twoFactorCodeForm.validateFields(async (err, values) => {
        if (!err) {
          await this.props.postTwoFactorCode(values.twoFactorCode);

          twoFactorCodeForm.resetFields();
          this.setState({
            oneTimePasswordQldLoading: true,
          });
          this.props.fetchTwoFactorCode();
        }
      });
      this.props.fetchQldBlueLinkageStatus();
    };

    const qldOtpDashboardModal = this.props.otpPreference === 'manual' ? (
      <TwoFactorModalComponent
        title='Queensland Blue Linkage'
        error={qldblueLinkageError}
        description='Please enter the one-time password, sent to you via SMS, when you receive it.'
        visible={this.state.qldblueLinkageModalVisible}
        handleCancel={() => {
          toggleLinkingModal();
          resetPollingDelay();
        }}
        handleSubmit={handleQldBlueOTP}
        initiateLinkage={initiateLinkage}
        hasStarted={!!this.state.qldblueLinkageStatus}
        otpSubmitted={this.state.qldblueLinkageOTPSubmitted}
        updates={this.state.qldblueLinkageUpdates}
      />
    ) : null;

    if (relatedOrgDashboards) {
      relatedOrgDashboards.sort((a, b) => {
        if (a.organization.name > b.organization.name) return 1;
        if (a.organization.name < b.organization.name) return -1;
        return 0;
      });
    }

    const dashboardPageTileCount = checkFeature('dashboardTileCount') && actionCardsIssueCount > 0 && <Tooltip placement="right" title='This number reflects the sum of all the "red" issues in this category, which require attention to lift compliance.'>({actionCardsIssueCount})</Tooltip>;

    return (
      <div>
        <Row>
          {httpFailed
            ? <Alert type="error" message="Couldn't retrieve data" />
            : <Spin spinning={httpInProgress}><div >
              <Collapse
                bordered={false}
                expandIconPosition={'right'}
                defaultActiveKey={this.state.dashboardImportantCardPanelToggle ? ['dashboardImportantCardPanelToggle'] : []}
                onChange={activeKey => this.handlePanelToggleChange(activeKey, 'dashboardImportantCardPanelToggle')}
                expandIcon={({ isActive }) => (<span><Icon type="down-circle-o" style={{
                  fontSize: '36px',
                  strokeWidth: '52',
                  color: 'gray',
                  stroke: '#dbe4e9',
                }} rotate={isActive ? 180 : 0}
                                                     /></span>)}
              >
                <Collapse.Panel key="dashboardImportantCardPanelToggle" header={<span style={{ fontSize: '22px' }}>You need to action this, right away {dashboardPageTileCount}</span>} style={customPanelStyle}>
                  <div style={{
                    display: 'flex', flexWrap: 'wrap',
                  }}
                  >
                    {actionCards.map(col => (
                      <Col key={col.key}>
                        <DashboardTile label={col.label} onClickHandler={this.createClickHandler({ tags: col.tags, orgSmart: col.orgTags, dashboardType: col.key })} hoverable statsBg={`rgba(${COLORS_MAP[col.color].join(',')}, 0.2)`} statsValue={mainOrgDashboard ? mainOrgDashboard[col.key] : 0} statsValueColor={`rgb(${COLORS_MAP[col.color].join(',')})`}/>
                      </Col>
                    ))}
                    {this.props.qldblueLinkageTileStatus && checkFeature('qldLinking')
                && <DashboardTile label={'Link QLD Accreditations'} onClickHandler={() => { startLinking('qld'); }} onButtonClick={this.createClickHandler({ tags: qldLinkageCard.tags, dashboardType: qldLinkageCard.key })} hoverable statsBg={'rgba(255, 196, 0, 0.2)'} statsValue={qldblueLinkageTileCount} statsValueColor={'rgb(255, 196, 0)'} />
                }
                    {checkFeature('ndwsLinkage') && <LinkageTile
                      canInitiateLinkage={this.getNDWSCanLink()}
                      createClickHandler={this.createClickHandler({ tags: ndwsLinkageCard.tags, dashboardType: ndwsLinkageCard.key })}
                      DashboardTile={DashboardTile} />}
                  </div>
                  {relatedOrgDashboards.length > 0 && showRelationshipAccredSummary ? relatedOrgDashboards.map((summary, idx) => (<Row key={idx}>
                    <Title level={4} type="secondary" >{summary.organization.name}</Title>
                    <div style={{
                      display: 'flex', flexWrap: 'wrap',
                    }}
                    >
                      {this.generatedOrgRelationshipSummary(summary).map(cards => cards)}
                    </div>

                  </Row>
                  ))
                    : null}

                </Collapse.Panel>
              </Collapse>
              <Collapse
                bordered={false}
                expandIconPosition={'right'}
                defaultActiveKey={this.state.dashboardCloserToCompliancePanelToggle ? ['dashboardCloserToCompliancePanelToggle'] : []}
                onChange={activeKey => this.handlePanelToggleChange(activeKey, 'dashboardCloserToCompliancePanelToggle')}
                expandIcon={({ isActive }) => (<span><Icon type="down-circle-o" style={{
                  fontSize: '36px',
                  strokeWidth: '52',
                  color: 'gray',
                  stroke: '#f5ece4',
                }} rotate={isActive ? 180 : 0}
                                                     /></span>)}
              >
                <Collapse.Panel key="dashboardCloserToCompliancePanelToggle" header={<span style={{ fontSize: '22px', color: '#82412C' }}>You're one step closer to compliance {checkFeature('dashboardTileCount') && closeToComplianceCardsIssueCount > 0 && `(${closeToComplianceCardsIssueCount})`}</span>} style={{ ...customPanelStyle, background: '#f5ece4' }}>
                  <div style={{
                    display: 'flex', flexWrap: 'wrap',
                  }}
                  >
                    {closeToComplianceCards.map(col => (
                      <Col key={col.key}>
                        <DashboardTile label={col.label} onClickHandler={this.createClickHandler({ tags: col.tags, orgSmart: col.orgTags, dashboardType: col.key })} hoverable statsBg={`rgba(${COLORS_MAP[col.color].join(',')}, 0.2)`} statsValue={mainOrgDashboard ? mainOrgDashboard[col.key] : 0} statsValueColor={`rgb(${COLORS_MAP[col.color].join(',')})`}/>
                      </Col>
                    ))}
                  </div>
                </Collapse.Panel>
              </Collapse>

              <Row style={{ backgroundColor: 'white', paddingBottom: '10px' }}>
                <Col md={24} xl={24}>
                  <Collapse
                    bordered={false}
                    expandIconPosition={'right'}
                    defaultActiveKey={this.state.dashboardStatsVisualPanelToggle ? ['dashboardStatsVisualPanelToggle'] : []}
                    onChange={activeKey => this.handlePanelToggleChange(activeKey, 'dashboardStatsVisualPanelToggle')}
                    expandIcon={({ isActive }) => (<span><Icon type="down-circle-o" style={{
                      fontSize: '36px',
                      strokeWidth: '52',
                      color: 'gray',
                      stroke: 'white',
                    }} rotate={isActive ? 180 : 0}
                                                         /></span>)}
                  >
                    <Collapse.Panel key="dashboardStatsVisualPanelToggle" header={<span style={{ fontSize: '22px', color: '#82412C' }}>How you're doing</span>} style={{ ...customPanelStyle, background: 'white' }}>
                      <div style={{
                        display: 'flex', flexWrap: 'wrap', justifyContent: 'center',
                      }}
                      >
                        <Card
                          style={{
                            marginRight: '16px', marginBottom: '16px', height: 'auto',
                          }}
                        >
                          <span>Non-compliant&nbsp;</span>
                          <span style={{ float: 'right' }}>Compliant</span>
                          <div style={{ display: 'flex', paddingTop: '5%' }}>
                            <div style={{
                              backgroundColor: `rgba(${COLORS_MAP[invalidCard.color].join(',')}, 0.2)`,
                              borderRadius: '50%',
                              width: `calc(300px*${invalidPercentage})`,
                              height: `calc(300px*${invalidPercentage})`,
                              minWidth: '60px',
                              minHeight: '60px',
                              maxHeight: '300px',
                              maxWidth: '300px',
                            }}
                            >
                              <Statistic
                                style={{
                                  display: 'block', margin: '0', marginTop: '50%', transform: 'translateY(-50%)', textAlign: 'center',
                                }}
                                value={mainOrgDashboard ? mainOrgDashboard[invalidCard.key] : 0}
                                valueStyle={{ color: `rgb(${COLORS_MAP[invalidCard.color].join(',')})` }}
                              />
                            </div>
                            <div style={{
                              display: 'flex',
                              position: 'relative',
                              backgroundColor: `rgba(${COLORS_MAP[compliantCard.color].join(',')}, 0.2)`,
                              borderRadius: '50%',
                              justifyContent: 'center',
                              width: `calc(300px*${compliantPercentage})`,
                              height: `calc(300px*${compliantPercentage})`,
                              minWidth: '60px',
                              minHeight: '60px',
                              maxHeight: '300px',
                              maxWidth: '300px',
                              left: '10px',

                            }}
                            >
                              <Statistic
                                style={{
                                  display: 'flex', alignItems: 'center',
                                }}
                                value={mainOrgDashboard ? mainOrgDashboard[compliantCard.key] : 0}
                                valueStyle={{ color: `rgb(${COLORS_MAP[compliantCard.color].join(',')})` }}
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </Col>
                {/* <Col md={24} xl={11}>
                  <Collapse
                    bordered={false}
                    expandIconPosition={'right'}
                    onChange={(activeKey) => this.handlePanelToggleChange(activeKey, 'dashboardSystemStatsPanelToggle')}
                    defaultActiveKey={this.state.dashboardSystemStatsPanelToggle ? ['dashboardSystemStatsPanelToggle'] : []}
                    expandIcon={({ isActive }) => (<span><Icon type="down-circle-o" style={{
                      fontSize: '36px',
                      strokeWidth: '52',
                      color: 'gray',
                      stroke: 'white',
                    }} rotate={isActive ? 180 : 0}
                                                                     /></span>)}
                  >
                    <Collapse.Panel key="dashboardSystemStatsPanelToggle" header={<span style={{ fontSize: '22px', color: '#82412C' }}>The bigger picture</span>} style={{ ...customPanelStyle, background: 'white' }}>
                      <div style={{
                        display: 'flex', flexWrap: 'wrap', justifyContent: 'center',
                      }}
                      >
                        <Card bordered={false}>
                          <StatsCard value={'2.3K'} description={'new checks performed in the last 30 days'} />
                          <StatsCard value={'5GB'} description={'of data uncovered during the last red flag'}/>
                          <StatsCard value={'87%'} description={'average compliance in total Oho system'}/>
                        </Card>
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </Col> */}
              </Row>
              <Row style={{ backgroundColor: 'white', paddingBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                {mainOrgDashboard ? <span style={{ marginLeft: '5px' }}>
                  <Tag color='#dcbfa6' style={{ color: 'black' }}>Active People: {mainOrgDashboard.total}</Tag>
                  <Tag color='#dcbfa6' style={{ color: 'black' }}>Active Verifiable: {mainOrgDashboard.verifiableAccredCount}</Tag>
                  <Tag color='#dcbfa6' style={{ color: 'black' }}>Active General: {mainOrgDashboard.genAccredCount}</Tag>
                </span> : null}
              </Row>
            </div>
            </Spin>
          }
        </Row>
        {qldOtpDashboardModal}
      </div>
    );
  }
}

const AccreditationsSummarySection = withRouter(connect(
  state => ({
    httpData: state.doc.getIn(['http', 'accreditationsSummary', 'data'], null),
    httpFailed: state.doc.getIn(['http', 'accreditationsSummary', 'failed'], false),
    httpInProgress: state.doc.getIn(['http', 'accreditationsSummary', 'inProgress'], true),
    otpPreference: state.doc.getIn(['http', 'fetchCurrentOrganization', 'data', 'otpPreference'], null),
    currentOrganizationId: state.doc.getIn(['http', 'fetchCurrentOrganization', 'data', 'id'], null),
    twoFactorState: state.doc.getIn(['auth', 'twoFactor', 'saved'], null),
    qldblueLinkageError: state.doc.getIn(['linkage', 'error'], null),
    qldblueLinkageTileStatus: state.doc.getIn(['http', 'qldblueLinkageCount', 'data', 'linkage', 'qld'], null),
    qldblueLinkageTileCount: state.doc.getIn(['http', 'qldblueLinkageCount', 'data', 'linkage', 'count'], null),
    qldblueLinkageResult: state.doc.getIn(['http', 'qldblueLinkageStatus', 'data', 'result'], null),
    qldblueLinkageStatus: state.doc.getIn(['http', 'qldblueLinkageStatus', 'data', 'status'], null),
    qldblueLinkageOTPSubmitted: state.doc.getIn(['http', 'qldblueLinkageStatus', 'data', 'otp'], null),
    qldblueLinkageUpdates: state.doc.getIn(['http', 'qldblueLinkageStatus', 'data', 'updates'], null),
    ndwsLinkageTileStatus: state.doc.getIn(['http', 'ndwsLinkageCount', 'data', 'linkage', 'ndws'], null),
    settings: state.doc.getIn(['http', 'fetchCurrentOrganization', 'data', 'settings'], null),
  }),
  actions,
)(AccreditationsSummarySectionComponent));


class DashboardPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showRelationshipAccredSummary: JSON.parse(window.localStorage.getItem('relationshipToggle')) || false };
  }

  componentWillMount() {
    if (!this.props.organizationId) return;
    this.props.fetchAccreditationsSummary(this.props.organizationId);
    if (checkFeature('orgStats')) this.props.fetchOrgStats();
  }

  componentWillUpdate(nextProps, nextState) {
    if (!nextProps.organizationId) return;
    // eslint-disable-next-line no-useless-return
    if (nextProps.organizationId === this.props.organizationId) return;
  }

  // toggleRelationshipAccredSummary = () => {
  //   this.setState({ showRelationshipAccredSummary: !this.state.showRelationshipAccredSummary });
  //   window.localStorage.setItem('relationshipToggle', !this.state.showRelationshipAccredSummary);
  // }

  handleClickAddMyAccred = () => {
    this.props.history.push(`/constituents/${this.props.authConstituentId}/accreditations`);
  }

  handleClickAddNewConst = () => {
    this.props.history.push('/constituents/new');
  }

  handleClickImport = () => {
    this.props.history.push('/constituents/import');
  }

  render() {
    const { accreditationSummaryData, authConstituentId, lastLoggedInTs, authUserFullName } = this.props;
    const accredTotals = accreditationSummaryData
      && Object.entries(accreditationSummaryData.toJS()).reduce((acc, [key, val]) => {
        if (key === 'total') return acc;
        return acc + val;
      }, 0);

    return (
      <div>
        <UserSummarySection lastLoggedInTs={lastLoggedInTs} authUserFullName={authUserFullName}/>
        <div>
          {/* {accreditationSummaryData !== null && accreditationSummaryData.size > 0
          && <div style={{ marginTop: -64, marginBottom: 40, width: '100%' }}>
            <Row>
              <Col xs={2}></Col>
              <Col style={{ textAlign: 'right' }} span={9}>
                <h1>Show Only My Organization</h1>
              </Col>
              <Col style={{ textAlign: 'center' }} span={4}>
                <Switch checked={this.state.showRelationshipAccredSummary} onChange={this.toggleRelationshipAccredSummary} />
              </Col>
              <Col style={{ textAlign: 'left' }} span={9}>
                <h1>Show My Related Organization</h1>
              </Col>
            </Row>
          </div>} */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {accredTotals === 0
              ? (
                <Empty
                  description={(
                    <div>
                      <div>There are currently no person accreditations to report on.</div>
                      <div style={{ marginTop: '16px' }}>
                        <Button size="large" type="primary" style={{ marginRight: '8px' }} onClick={this.handleClickAddMyAccred} loading={authConstituentId === null}>Add my accreditation(s)</Button>
                        <Button size="large" type="primary" style={{ marginRight: '8px' }} onClick={this.handleClickAddNewConst}>Add a new person</Button>
                        <Button size="large" type="primary" style={{ marginRight: '8px' }} onClick={this.handleClickImport}>Bulk import people</Button>
                      </div>
                    </div>
                  )}
                  image={<span style={{ fontSize: '8em' }}><Icon type="bar-chart" /></span>}
                />
              )
              : <AccreditationsSummarySection
                showRelationshipAccredSummary={true}
                orgId={this.props.organizationId}
                // onToggleChange={this.toggleRelationshipAccredSummary}
                />
            }
          </div>
        </div>
      </div>
    );
  }
}

export const DashboardPage = connect(
  state => ({
    authConstituentId: state.doc.getIn(['authMr', 'constituent', 'id'], null),
    accreditationSummaryData: state.doc.getIn(['http', 'accreditationsSummary', 'data'], null),
    organizationId: state.doc.getIn(['authMr', 'organizationId'], null),
    lastLoggedInTs: state.doc.getIn(['authMr', 'lastLoggedInTs'], null),
    authUserFullName: state.doc.getIn(['authMr', 'constituent', 'fullName'], null),
  }),
  actions,
)(DashboardPageComponent);
