import _ from 'lodash';
import {
  Alert, Button, Spin, Form, Input, Modal, Progress,
} from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';

const LINKAGE_STATUS = {
  FAILED: 'failed',
  FINISHED: 'finished',
  LOADING: 'loading',
  RUNNING: 'running',
  STARTED: 'started',
  SYNCING: 'syncing',
  WAITING: 'waiting',
};

@Form.create()
class NDWSSyncOTPComponent extends PureComponent {
  handleNDWSOTP = async form => {
    form.validateFields(async (err, values) => {
      if (err) return;
      await this.props.handleSubmit(values.otp);
    });
  }

  render() {
    const { form, otpError } = this.props;

    const handleKeyUp = evt => {
      if (evt.key === 'Enter') this.handleNDWSOTP(form);
    };

    return (
      <Form layout="vertical" style={{ paddingTop: '10px' }} >
        <p>Please enter your one-time password, once you have received it.</p>
        <Form.Item label="One-Time Password:">
          {form.getFieldDecorator('otp', {
            rules: [{ required: true, message: 'Please enter your one-time password, once you have received it' }],
          })(<Input onKeyUp={handleKeyUp} />)}
        </Form.Item>
        {otpError && <p style={{ color: 'red', paddingBottom: '5px' }}>{otpError}</p>}
        <Button type='primary' onClick={() => this.handleNDWSOTP(form)}>Submit</Button>
      </Form>
    );
  }
}

@Form.create()
class LinkageModalComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.pollerRef = React.createRef();
    this.state = {
      cannotLink: false,
      errorMessage: '',
      isLoading: false,
      otpError: '',
      otpSubmitted: false,
      pollerMessage: '',
      pollerStatus: '',
    };
  }

  componentDidMount() {
    this.checkLinkageStatus();
  }

  componentWillUnmount() {
    clearInterval(this.pollerRef);
  }

  componentDidUpdate(prevProps, prevState) {
    const { linkNDWSResponse, linkErrorMessage } = this.props;
    if (linkErrorMessage) {
      this.setState({ cannotLink: true, errorMessage: linkErrorMessage });
      return;
    }
    const status = _.get(linkNDWSResponse, ['message', 'status'], '');
    const message = _.get(linkNDWSResponse, ['message', 'message'], '');

    let error;
    if (status === LINKAGE_STATUS.FAILED) error = message;

    this.setState({ pollerStatus: status, pollerMessage: message, errorMessage: error });

    // handle otp waiting
    if (status === LINKAGE_STATUS.WAITING && this.state.otpSubmitted) this.setState({ pollerStatus: 'loading' });

    // TODO MAYBE REMOVE THIS? stop polling if process has succeeded
    if (status === LINKAGE_STATUS.FINISHED) clearInterval(this.pollerRef);
  }

  handleStartLinkage = async () => {
    this.setState({ isLoading: true });
    await this.props.startLinkage();
    this.setState({ isLoading: false });
  }

  checkLinkageStatus = async () => {
    this.setState({ isLoading: true });
    await this.props.linkageStatus();
    this.setState({ isLoading: false });
    this.pollerRef = setInterval(async () => {
      this.props.linkageStatus();
    }, 5000);
  }

  handleSubmitOTP = async input => {
    this.setState({ isLoading: true, otpError: '' });
    const { failed } = await this.props.handleOTP(input, this.props.otpPath);
    if (failed) this.setState({ isLoading: false, otpError: 'Unknown error' });
    else this.setState({ isLoading: false, otpSubmitted: true });
  }

  getPercentage = () => {
    try {
      const [count, total] = this.state.pollerMessage.split('/').map(Number);
      return (count / total) * 100;
    } catch (error) {
      return 0;
    }
  }

  currentStatus = () => {
    if (this.state.cannotLink) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px', paddingBottom: '10px' }}>
          <p>{this.state.errorMessage}</p>
        </div>
      );
    }
    if (this.state.isLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px', paddingBottom: '10px' }}>
          <Spin />
        </div>
      );
    }
    let status;
    switch (this.state.pollerStatus) {
      case LINKAGE_STATUS.LOADING:
        status = (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px', paddingBottom: '10px' }}>
            <Spin />
          </div>
        );
        break;
      case LINKAGE_STATUS.FINISHED:
      case LINKAGE_STATUS.FAILED:
      case '':
        status = (
          <>
            {this.state.pollerStatus === LINKAGE_STATUS.FINISHED && <Alert message={'Linkage process completed!'} type="success" showIcon />}
            {this.state.pollerStatus === LINKAGE_STATUS.FAILED && this.state.errorMessage
            && <div style={{ display: 'flex', color: 'red' }}>
              <strong style={{ marginRight: '5px' }}>Failed:</strong>
              <strong>{this.state.errorMessage}</strong>
            </div>}
            <p style={{ paddingTop: '10px' }}>Would you like to start NDWS linkage process?</p>
            <div style={{ paddingTop: '25px', paddingBottom: '25px', display: 'flex', justifyContent: 'center' }}>
              <Button onClick={this.handleStartLinkage} >Start NDWS Linkage process</Button>
            </div>
          </>
        );
        break;
      case LINKAGE_STATUS.STARTED:
      case LINKAGE_STATUS.RUNNING:
        status = (
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '25px', paddingBottom: '25px', textAlign: 'center',
          }}
          >
            <p><strong style={{ color: 'gray' }}>Status</strong>: <strong style={{ color: 'green' }}>{this.state.pollerMessage}</strong></p>
            <Spin />
          </div>
        );
        break;
      case LINKAGE_STATUS.WAITING:
        status = <NDWSSyncOTPComponent handleSubmit={input => this.handleSubmitOTP(input)} otpError={this.state.otpError} />;
        break;
      case LINKAGE_STATUS.SYNCING:
        status = (
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '25px', paddingBottom: '25px', textAlign: 'center',
          }}
          >
            <p><strong style={{ color: 'gray', marginRight: '5px' }}>Processing...</strong><strong style={{ color: 'green' }}>{this.state.pollerMessage}</strong></p>
            <Progress
              percent={this.getPercentage()}
              status={'success'}
              showInfo={false}
            />
          </div>
        );
        break;
      default:
        status = (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px', paddingBottom: '10px' }}>
            <Spin />
          </div>
        );
    }

    return status;
  }

  linkageNotAllowed = () => (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px', paddingBottom: '10px' }}>
      <p>This linkage process is currently managed by Oho.</p>
    </div>
  )

  render() {
    const {
      canInitiateLinkage,
      handleCancel,
      title,
      visible,
    } = this.props;

    return (
      <Modal
        title={title}
        onCancel={handleCancel}
        visible={visible}
        style={{ minWidth: 400 }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        {canInitiateLinkage
          ? this.currentStatus()
          : this.linkageNotAllowed()}
      </Modal>
    );
  }
}
const LinkageModal = connect(
  state => ({
    ndwsLinkageTileCount: state.doc.getIn(['http', 'ndwsLinkageCount', 'data', 'linkage', 'count'], null),
    ndwsLinkageTileStatus: state.doc.getIn(['http', 'ndwsLinkageCount', 'data', 'linkage', 'ndws'], null),
    linkNDWSResponse: state.doc.getIn(['ndwsLinkage'], false),
    linkErrorMessage: state.doc.getIn(['linkageError', 'ndws'], null),
  }),
  actions,
)(LinkageModalComponent);

export { LinkageModal };
