/* eslint prefer-promise-reject-errors: ["off"] */
import uuid4 from 'uuid/v4';

import { message } from 'antd';
import { simpleAjaxDispatch } from './http';
import { watchCorrelationId } from './longpoller';
import { UNKNOWN_HTTP_ERROR_MESSAGE } from '../defaults';

export function fetchOrganization(organizationId) {
  return simpleAjaxDispatch(
    `/organizations/${organizationId}`,
    'fetchOrganization',
  );
}

export function fetchControlOrganization(organizationId) {
  return simpleAjaxDispatch(
    `/organizations/${organizationId}`,
    'fetchControlOrganization',
  );
}

export function patchOrganization(organizationId, json) {
  return (dispatch, getState) => simpleAjaxDispatch(
    `/organizations/${organizationId}`,
    'patchOrganization',
    { method: 'PATCH', json },
  )(dispatch, getState).then(action => {
    if (action.success) message.success('Organization settings saved successfully');
    else message.warning('Couldn\'t save your organisation\'s settings right now!');
  });
}

export function patchControlOrganization(organizationId, json) {
  return (dispatch, getState) => simpleAjaxDispatch(
    `/organizations/${organizationId}/control`,
    'patchControlOrganization',
    { method: 'PATCH', json },
  )(dispatch, getState).then(action => {
    if (action.success) message.success('Organization settings saved successfully');
    else message.warning('Couldn\'t update this organisation\'s settings.');
  });
}

export function fetchOrganizations(query) {
  return simpleAjaxDispatch(
    `/organizations?query=${query}`,
    'fetchOrganizations',
  );
}

export function fetchOrganizationEmail(organizationId) {
  return simpleAjaxDispatch(
    `/organizations/${organizationId}/emails`,
    'fetchOrganizationEmail',
  );
}

export function postOrganizationEmail(organizationId, json) {
  return simpleAjaxDispatch(
    `/organizations/${organizationId}/emails`,
    'postOrganizationEmail',
    { method: 'POST', meta: { organizationId }, json },
  );
}

export function patchDefineTypeRequestForOrg(organizationId) {
  return simpleAjaxDispatch(
    `/organizations/${organizationId}/define_types_requests`,
    'patchDefineTypeRequestForOrg',
    { method: 'PATCH', meta: { organizationId } },
  );
}

export function postPoliceCheckRequestForOrg(organizationId) {
  return (dispatch, getState) => simpleAjaxDispatch(
    `/organizations/${organizationId}/police_check_request`,
    'postPoliceCheckRequestForOrg',
    { method: 'POST', meta: { organizationId } },
  )(dispatch, getState).then(action => {
    if (action.success) message.success('Police Checks requested successfully');
    else message.warning('Couldn\'t request Police Checks for this organisation');
  });
}

export function postOrganizationCredentials(organizationId, json) {
  const allData = { ...json, correlationId: uuid4() };
  let finalValue = null;
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const watchProm = watchCorrelationId(allData.correlationId, (err, value) => {
      if (err) return;
      // routingKey is like event.rpc.result.<ev>.<qualName>
      const event = value.routingKey.split('.')[3];
      if (event === 'complete') {
        if (finalValue) {
          if (finalValue.event === 'value') {
            resolve(finalValue.content);
          } else if (finalValue.event === 'error') {
            reject(finalValue.content);
          } else {
            reject({ _errors: [UNKNOWN_HTTP_ERROR_MESSAGE] });
          }
        } else {
          reject({ _errors: [UNKNOWN_HTTP_ERROR_MESSAGE] });
        }
      } else {
        finalValue = value;
        finalValue.event = event;
      }
    })
      .then(() => {
        simpleAjaxDispatch(`/organizations/${organizationId}/institutions/credentials`, 'postOrganizationCredentials', {
          json: allData, method: 'POST', meta: { organizationId },
        })(dispatch, getState)
          .then(action => {
            if (action.failed) {
              reject(action.data);
            }
          })
          .catch(err => reject({ _errors: [err] }))
          .finally(() => watchProm.cancel());
      })
      .catch(() => { });
  });
}

export function fetchCurrentOrganization() {
  return simpleAjaxDispatch(
    '/organizations/me',
    'fetchCurrentOrganization',
  );
}

export function fetchOrganizationSettings() {
  return simpleAjaxDispatch(
    '/organizations/settings',
    'fetchOrganizationSettings',
  );
}

export function fetchNCCOauthUrl() {
  return simpleAjaxDispatch(
    '/oauth/ncc/authorize',
    'fetchNCCOauthUrl',
  );
}

export function fetchNCCOPingUrl() {
  return simpleAjaxDispatch(
    '/oauth/ncc/ping',
    'fetchNCCOauthUrl',
  );
}

export function postOrganizationQldDefaults(organizationId, json) {
  return simpleAjaxDispatch(`/organizations/${organizationId}/institutions/qldblue/defaults`, 'postOrganizationQldblueDefaults', {
    json, method: 'POST', meta: { organizationId },
  });
}

export function postOrganizationNDWSDefaults(organizationId, json) {
  return simpleAjaxDispatch(`/organizations/${organizationId}/settings/ndws/defaults`, 'postOrganizationNDWSDefaults', {
    json, method: 'POST', meta: { organizationId },
  });
}

export function postOrganizationNSWOneTimeCheckDefaults(organizationId, json) {
  return simpleAjaxDispatch(`/organizations/${organizationId}/settings/nsw_otc/defaults`, 'postOrganizationNSWDOneTimeCheckDefaults', {
    json, method: 'POST', meta: { organizationId },
  });
}
